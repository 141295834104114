<template>
  <div>
    <div class="Top_box">
      <div
        style="
          display: flex;
          align-items: center;
          justify-items: center;
          margin: 5px;
        "
      >
        <img
          src="../../assets/images/Examine_img/comp.png"
          style="width: 15px"
          alt=""
        />
        <el-select
          placeholder="请选择公司"
          class="select"
          v-model="Company"
          @change="togetArea1"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :value="item.id"
            :label="item.companyName"
          >
            {{ item.companyName }}
          </el-option>
        </el-select>
      </div>
      <div class="box_">
        <div
          class="_item"
          v-for="(item, index) in option2"
          :key="index"
          :aria-disabled="item.isHaveTopic"
          @click="chooseArea(index, item)"
          v-show="item.isHaveTopic"
          :class="{
            action: curindex == index,
            Noaction: item.isHaveTopic === 0,
          }"
        >
          <span v-if="item.isHaveTopic"> {{ item.areaName }}</span>
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            content="该区域没有题库"
            placement="top"
          >
            <span> {{ item.areaName }}</span>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="mid_box">
      <el-button type="primary" class="edit" @click="toChooseTopic"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <span style="margin-top: 5px">
        <img
          style="cursor: pointer"
          @click="delItem"
          src="../../assets/images/Examine_img/del.png"
          alt=""
        />
      </span>
      <div class="_Select" style="margin-right: 15px">
        <span>年份：</span>
        <el-date-picker
          v-model="year"
          format="yyyy"
          value-format="yyyy"
          type="year"
          placeholder="选择年份"
          @change="getTableData"
        >
        </el-date-picker>
      </div>
      <el-checkbox
        label="已完成"
        v-model="ischeck"
        @change="getTableData"
        true-label="2"
      ></el-checkbox>
      <el-checkbox
        label="未完成"
        v-model="ischeck"
        @change="getTableData"
        true-label="4"
      ></el-checkbox>
      <el-checkbox
        label="有问题点"
        v-model="ischeck"
        @change="getTableData"
        true-label="5"
      ></el-checkbox>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.slice(start, end)"
      tooltip-effect="dark"
      height="490"
      style="width: 100%"
      highlight-current-row
      @row-dblclick="change"
      @selection-change="handleSelectionChange"
      @select="selectChange"
      @select-all="selectAll"
      @row-click="handleRow"
      :cell-style="cellStyle"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      id="table"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column label="区域" prop="auditAreaName" width="200">
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="status" v-show="scope.row.status === 0">
            <div class="statusbox"></div>
            未抽题
          </span>
          <span class="status" v-show="scope.row.status === 1"
            ><div style="background: #7fc7fa" class="statusbox"></div>
            已抽题</span
          >
          <span class="status" v-show="scope.row.status === 2"
            ><div class="statusbox1"></div>
            已完成</span
          >
          <span class="status" v-show="scope.row.status === 3"
            ><div class="statusbox"></div>
            整改完成</span
          >
          <span class="status" v-show="scope.row.status === 4"
            ><div style="background: #f2729c" class="statusbox"></div>
            未完成</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="remark" label="备注" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="Company1"
          placeholder="请选择"
          class="Company_choose"
          @change="togetArea"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">选择区域:</span>
        <el-select
          v-model="Area1"
          placeholder="请选择"
          class="Company_choose"
          @change="changeArea"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :disabled="!item.isHaveTopic"
            :label="item.areaName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex" class="text">
        <span style="margin: 10px">添加备注:</span
        ><textarea v-model="remark" style="padding: 10px"></textarea>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <!-- <span
            @click="chooseCt"
            style="margin: 10px; color: #4274c0; cursor: pointer"
            ><i class="el-icon-plus"></i> 添加范畴</span
          > -->
        </div>
        <div style="display: flex">
          <span style="margin: 15px; margin-left: 10%"></span>
          <div class="topic">
            <div>涉及方面</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img
              src="../../assets/images/Examine_img/top.png"
              @click="isshow = !isshow"
              alt=""
            />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in titlelist"
              :key="index"
            >
              <span
                style="
                  margin: 15px;
                  margin-left: 10%;
                  margin-bottom: 0px;
                  margin-top: 0px;
                "
              ></span>
              <div class="topic1">
                <div>{{ item.auditTypeName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img
                    @click="sub(item)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt=""
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled; margin-top: 5px"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddInsertAreaAudit"
          >确定抽题</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加范畴" :visible.sync="dialogVisibleC" width="30%">
      <div style="height: 300px; overflow: hidden; overflow-y: auto">
        <el-tree
          :data="Ctdata"
          show-checkbox
          node-key="id"
          ref="tree"
          @check="iScheck"
          highlight-current
          :props="defaultProps"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleC = false">取 消</el-button>
        <el-button type="primary" @click="gettitle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post } from '../../api/http'

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      options: [],
      option2: [],
      tableData: [],
      year: new Date().getFullYear() + '',
      Company: '',
      Area: '',
      Company1: '',
      Area1: '',
      remark: '',
      isshow: false,
      number: '',
      curArea: '',
      titlelist: '',
      CurId: '',
      CurName: '',
      ischeck: '4',
      curList: '',
      curItem: [],
      start: 0,
      end: 10,
      nodeKey: [],
      listID: [],
      extractCount: 0,
      curindex: null,
      currentPage1: 0,
      currentPage: null,
      curSize: 10,
      Ctdata: [],
      curID: '',
      allListID: [],
      allTreeList: [],
      nodeID: [],
      halfNode: [],
      cname: [],
      dialogVisibleC: false,
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
      queryAuditType: 0,
      areaItem: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != 'Examine_topic') {
      to.meta.ifDoFresh = true
    }
    next()
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false
      this.options = this.$store.state.companyUserList
      this.queryAuditType = this.$route.query.fromQuery
      this.getTableData()
    } else if (Number(this.$route.query.isOver) == 1) {
      this.getTableData()
    }
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  methods: {
    cellStyle() {
      return 'cursor:pointer;'
    },
    gettitle() {
      post('/api/AreaAudit/GetTitleList?AreaId=' + this.Area1, {
        data: JSON.stringify([{ AuditTypeId: 6 }]),
      }).then((res) => {
        this.dialogVisibleC = false
        res.data.forEach((i) => {
          i.extractCount = i.titleCount
        })
        this.titlelist = res.data
        this.nodeID = []
      })
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = ''
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount
    },
    async toAddInsertAreaAudit() {
      this.extractCount = 0
      if (this.Company1 === '' || this.Area1 === '') {
        this.$message({
          type: 'success',
          message: '请填写完整信息',
        })
        return
      }
      this.titlelist.forEach((e) => {
        this.extractCount += Number(e.extractCount)
      })
      for (let index = 0; index < this.titlelist.length; index++) {
        if (this.titlelist[index].extractCount !== 0) {
          this.curList = JSON.stringify(this.titlelist)
          break
        }
      }
      if (!this.curList) return
      this.cname = ''
      this.option2.forEach((i) => {
        if (i.id === this.Area1) {
          this.cname = i.areaName
          this.cNO = i.areaNo
        }
      })
      var data = {
        jsonData: {
          data: this.curList,
        },
        areaAudit: {
          auditAreaNo: this.cNO,
          auditAreaName: this.cname,
          upCompanyId: this.Company1 + '',
          remark: this.remark,
          auditType: this.queryAuditType,
          chargeUserId: 'string',
          planId: 'string',
          saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
          saveUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
        },
      }
      await post('/api/AreaAudit/InsertAreaAudit?AreaId=' + this.Area1, data)
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })

          this.dialogVisible = false
          this.ischeck = '4'
        })
        .then(() => {
          this.getTableData().then(() => {
            this.change(this.tableData[0])
          })
        })
        .then(() => {})
    },
    chooseCt() {
      this.Ctdata = []
      this.nodeID = []
      get('/api/AreaAudit/GetAuditTypeList?AreaId=' + this.Area1).then(
        (res) => {
          this.Ctdata = res.data
          this.gettitle()
        }
      )
    },
    handleTreeList(item, curIdList) {
      item.forEach((k, i) => {
        if (k.auditTypelist.length > 0) {
          this.handleTreeList(k.auditTypelist, curIdList)
        }
        if (curIdList.includes(k.id)) {
          item.splice(i, 1)
        }
      })
    },
    changeArea() {
      this.nodeKey = []
      this.chooseCt()
      // this.$refs.tree.setCheckedKeys([]);
    },
    change(e) {
      sessionStorage.setItem('item', JSON.stringify(e))
      this.$router.push(
        '/Examine_topic?Id=' +
          e.id +
          '&auditAreaNo=' +
          e.auditAreaNo +
          '&upCompanyId=' +
          e.upCompanyId +
          '&check=' +
          this.ischeck +
          '&extractCount=' +
          this.extractCount +
          '&queryAuditType=' +
          this.queryAuditType +
          '&status=' +
          e.status
      )
    },
    delItem() {
      this.$confirm(
        '此操作将永久删除该' + this.CurName + '检查区域',
        '是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          deleteMethod('/api/AreaAudit?Id=' + this.CurId).then((res) => {
            this.$message({
              type: res.code === '200' ? 'success' : 'info',
              message: res.message,
            })
            this.getTableData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    iScheck(e, v) {
      this.nodeID = v.checkedNodes
      this.nodeKey = v.checkedKeys
      this.halfNode = v.halfCheckedNodes
    },
    chooseArea(index, item) {
      this.Area1 = item.id
      this.curindex = index
      this.areaItem = item
      this.getTableData()
    },
    async getTableData() {
      await get(
        '/api/AreaAudit/GetByAreaAudit?CompId=' +
          this.Company +
          '&AreaName=' +
          (JSON.stringify(this.areaItem) == '{}'
            ? ''
            : this.areaItem.areaName) +
          '&AreaNo=' +
          '&Status=' +
          (this.ischeck=='5'?'': this.ischeck)+
          '&Years=' +
          this.year +
          '&AuditType=' +
          this.queryAuditType
      ).then((res) => {
        if(this.ischeck==5){
          this.tableData = res.data.filter(item => item.failCount > 0)
        }else{
          this.tableData = res.data
        }
      })
    },
    async togetArea1() {
      this.option2 = []
      this.curindex = null
      this.areaItem = {}
      await get('/api/Area/GetByCompId?CompId=' + this.Company).then((res) => {
        this.option2 = res.data.filter(item => item.isHaveTopic)
      })
      this.getTableData()
    },
    togetArea() {
      get('/api/Area/GetByCompId?CompId=' + this.Company1).then((res) => {
        this.curindex = ''
        this.Company = this.Company1
        this.option2 = res.data.filter(item => item.isHaveTopic)
      })
    },
    toChooseTopic() {
      //this.$refs.tree.setCheckedKeys([]);
      this.dialogVisible = true
      if (this.Area1) this.chooseCt()
      this.titlelist = ''
      this.Company1 = this.Company
      this.nodeKey = []
    },
    handleRow(e) {
      this.curItem = e
      this.CurId = e.id
      this.CurName = e.auditAreaName
      //this.$refs.multipleTable.toggleRowSelection(e);
      // this.selectChange(this.handleSelectionList);
    },
    toSearch() {
      get(
        '/api/AreaAudit/GetByAreaAudit?AreaName=' +
          this.Area +
          '&Status=' +
          this.ischeck +
          '&AuditType=' +
          this.queryAuditType
      ).then((res) => {
        this.tableData = res.data
      })
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value
    },

    selectChange(selection, v) {
      this.curItem = v
      this.CurId = v.id
      this.$refs.multipleTable.setCurrentRow(v)
    },
    handleRowClick(row) {
      console.log(row)
      //this.$refs.multipleTable.toggleRowSelection(row);
      // this.selectChange(this.handleSelectionList);
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1
      }
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
</style>
<style lang="less" scoped>
.Top_box {
  background: none;
  padding-bottom: 0px;
  font-size: 20px;
}
.select {
  background: #e7e9ec !important;
}
.statusbox {
  margin: 5px;
  width: 10px;
  height: 10px;
}
.statusbox1 {
  margin: 5px;
  width: 10px;
  height: 10px;
  background: url(../../assets/images/Examine_img/ok.png);
  background-size: 100%;
}
::v-deep .select .el-input__inner {
  background-color: transparent !important;
  //   border-color: rgba(255, 255, 255, 0.5);
  color: #000000;
  border: 0px;
  height: 49px;
  width: 240px;
  font-size: 20px;
  text-size-adjust: 20px;
  .el-input__inner::placeholder {
    color: rgb(0, 0, 0);
    text-align: center;
  }
}
.Noaction {
  opacity: 0.3;
}
option {
  background: #ffffff;
  line-height: 20px;
  border: 0px;
}
.mid_box {
  background: #ffffff;
  padding: 4px;
  margin-bottom: 0px;
  border-bottom: 1px solid #dcdcdc;
}
.status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_ {
  display: flex;
  width: 100%;
  margin-left: 15px;
  overflow-x: auto;
}
// ::-webkit-scrollbar {
//   height: 8px;
//   background-color: #aa1010;
// }
::-webkit-scrollbar {
  margin-top: 3px;
  height: 6px;
  width: 0 !important;
} //设置滚动条显示隐藏
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
  background-color: rgb(218, 216, 216);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgb(92, 90, 90);
  /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
}

.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
._item {
  width: 147px;
  height: 49px;
  flex-shrink: 0;
  color: #000000;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
img {
  margin-left: 10px;
}
@import '../../assets/style/Examine_CSS.css';
</style>
